@tailwind base;
@tailwind components;
@tailwind utilities;
 
@layer base {
  :root {
    --header: 219, 50%, 99%, 1;
    --background: linear-gradient(180deg, #EBF2FB 0%, #D9E6F7 57%);
    --foreground: 220, 60%, 92%, 1;
    --saru-blue: 200, 26%, 86%, 1;

    --card: 0 0% 100%;
    --card-foreground: 240 10% 3.9%;
 
    --popover: 220, 50%, 99%, 1;
    --popover-foreground: 240 10% 3.9%;
    --popopver-text: 0, 0%, 5%, 1;
 
    --primary: 0, 0%, 5%, 1;
    --primary-foreground: 0 0% 98%;
 
    --secondary: 214, 10%, 30%, 1;
    --secondary-foreground:220, 60%, 92%, 1;
    --secondary-background: 220, 50%, 99%, 1;

    --tertiary: 214, 10%, 20%, 1;
 
    --muted: 240 4.8% 95.9%;
    --muted-foreground: 240 3.8% 46.1%;
    --muted-background: 231, 4%, 35%, 1;
 
    --accent: 214, 93%, 43%, 1;
    --accent-foreground: 240 5.9% 10%;
    --accent-secondary:214, 70%, 67%, 1;
 
    --destructive: 4, 47%, 11%, 1;
    --destructive-foreground: 7, 69%, 90%, 1;
    --destructive-background: 7, 75%, 82%, 1;

    --warning: 29, 91%, 48%, 1;
    --warning-foreground: 28, 75%, 78%, 1;
    --warning-background: 28, 67%, 95%, 1;

    --positive: 155, 65%, 38%, 1;
    --pending: 50, 85%, 40%, 1;

    --dialog-content: 220, 60%, 98%, 1;
    --dialog-content-secondary: 218, 61%, 82%, 1;
    --dialog-body: 216, 59%, 93%, 1;

    --menu-content: 220, 50%, 99%, 1;

    --border: 214, 10%, 90%, 1;
    --input: 240 5.9% 90%;
    --ring: 240 10% 3.9%;
    --divider: 192, 5%, 40%, 0.2;
 
    --radius: 0.5rem;
  }
  

  .dark {
    --header: 214, 40%, 5%, 1;
    --background: 214, 40%, 5%, 1;
    --background: 214, 40%, 5%, 1;
    --foreground: 214, 30%, 12%, 1;
    --saru-blue: 200, 26%, 86%, 1;
 
    --card: 240 10% 3.9%;
    --card-foreground: 0 0% 98%;
 
    --popover: 214, 35%, 20%, 1;
    --popover-foreground: 0 0% 98%;
    --popopver-text:214, 10%, 96%, 1;
 
    --primary: 214, 10%, 93%, 1;
    --primary-foreground: 240 5.9% 10%;
 
    --secondary: 214, 10%, 42%, 1;
    --secondary-foreground: 214, 30%, 16%, 1;
    --secondary-background: 214, 30%, 12%, 1;

    --tertiary: 214, 10%, 75%, 1;
 
    --muted: 240 3.7% 15.9%;
    --muted-foreground: 240 5% 64.9%;
    --muted-background: 231, 4%, 35%, 1;
 
    --accent: 214, 93%, 43%, 1;
    --accent-foreground: 0 0% 98%;
    --accent-secondary:214, 70%, 67%, 1;
 
    --destructive: 7, 76%, 55%, 1;
    --destructive-foreground: 4, 47%, 9%, 1;
    --destructive-background: 4, 47%, 11%, 1;
 
    --warning: 29, 91%, 48%, 1;
    --warning-foreground: 28, 52%, 14%, 1;
    --warning-background: 24, 38%, 7%, 1;

    --positive: 155, 65%, 38%, 1;
    --pending: 50, 80%, 35%, 1;

    --dialog-content: 214, 30%, 10%, 1;
    --dialog-content-secondary: 218, 61%, 82%, 1;
    --dialog-body: 214, 40%, 5%, 1;

    --menu-content: 214, 35%, 8%, 1;
 
    --border: 214, 10%, 20%, 1;
    --input: 240 3.7% 15.9%;
    --ring: 240 4.9% 83.9%;
    --divider: 192, 5%, 40%, 1;
  }
}

.Toastify__toast {
  background-color: hsla(var(--dialog-content)) !important;
}

html, body {
  scroll-behavior: smooth
}


::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: hsla(var(--border));
  border-radius: 0px;
}

::-webkit-scrollbar-thumb {
  background-color: hsla(var(--divider));
  border-radius: 0px;
  border: 1px solid transparent;
}
 
@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}


@keyframes interchange-background {
  0%, 49% {
    background-image: url('../../public/images/backgrounds/background-1.svg');
  }
  51%, 100% {
    background-image: url('../../public/images/backgrounds/background-2.svg');
  }
} 

.landing-bg {
  animation: interchange-background 2.25s infinite;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

@media (prefers-reduced-motion: reduce) {
  .landing-bg {
    animation: none;
    background-image: url('../../public/images/backgrounds/background-1.svg');
  }
}

.clip-path-none{
  clip-path: none !important;
}

.rabbet-clip {
  --edge-size: 8px;
  clip-path: polygon(
    0 var(--edge-size), 
    var(--edge-size) var(--edge-size), 
    var(--edge-size) 0, 
    calc(100% - var(--edge-size)) 0, 
    calc(100% - var(--edge-size)) var(--edge-size), 
    100% var(--edge-size), 
    100% calc(100% - var(--edge-size)), 
    calc(100% - var(--edge-size)) calc(100% - var(--edge-size)), 
    calc(100% - var(--edge-size)) 100%, 
    var(--edge-size) 100%, 
    var(--edge-size) calc(100% - var(--edge-size)), 
    0 calc(100% - var(--edge-size))
  );
}

.pfp-clip{
  clip-path: polygon(20% 0, 80% 0, 80% 10%, 90% 10%, 90% 20%, 100% 20%, 100% 80%, 90% 80%, 90% 90%, 80% 90%, 80% 100%, 20% 100%, 20% 90%, 10% 90%, 10% 80%, 0 80%, 0 20%, 10% 20%, 10% 10%, 20% 10%);
}

.double-rabbet-clip{
  --edge-size: 16px;
  clip-path: polygon(
    var(--edge-size) 0,
    calc(100% - var(--edge-size)) 0,
    calc(100% - var(--edge-size)) calc(var(--edge-size)/2),
    calc(100% - calc(var(--edge-size) / 2)) calc(var(--edge-size)/2),
    calc(100% - calc(var(--edge-size) / 2)) var(--edge-size),
    100% var(--edge-size),
    100% calc(100% - var(--edge-size)),
    calc(100% - calc(var(--edge-size) / 2)) calc(100% - var(--edge-size)),
    calc(100% - calc(var(--edge-size) / 2)) calc(100% - calc(var(--edge-size) / 2)),
    calc(100% - var(--edge-size)) calc(100% - calc(var(--edge-size) / 2)),
    calc(100% - var(--edge-size)) 100%,
    var(--edge-size) 100%,
    var(--edge-size) calc(100% - calc(var(--edge-size) / 2)),
    calc(var(--edge-size) / 2) calc(100% - calc(var(--edge-size) / 2)),
    calc(var(--edge-size) / 2) calc(100% - var(--edge-size)),
    0 calc(100% - var(--edge-size)),
    0 var(--edge-size),
    calc(var(--edge-size) / 2) var(--edge-size),
    calc(var(--edge-size) / 2) calc(var(--edge-size)/2),
    var(--edge-size) calc(var(--edge-size) / 2)
  );
}

.double-rabbet-inner-clip{
  --edge-size: 8px;
  clip-path: polygon(
    var(--edge-size) 0, 
    calc(100% - var(--edge-size)) 0, 
    calc(100% - var(--edge-size)) var(--edge-size), 
    100% var(--edge-size), 
    100% calc(100% - var(--edge-size)), 
    calc(100% - var(--edge-size)) calc(100% - var(--edge-size)), 
    calc(100% - var(--edge-size)) 100%, 
    var(--edge-size) 100%, 
    var(--edge-size) calc(100% - var(--edge-size)), 
    0 calc(100% - var(--edge-size)), 
    0 var(--edge-size), 
    var(--edge-size) var(--edge-size) 
  )
}

.double-rabbet-v2-inner-clip{
  --edge-size: 8px;
  clip-path: polygon(
    0 0, 
    100% 0, 
    100% calc(100% - var(--edge-size)), 
    calc(100% - var(--edge-size)) calc(100% - var(--edge-size)),
    calc(100% - var(--edge-size)) 100%, 
    var(--edge-size) 100%, 
    var(--edge-size) calc(100% - var(--edge-size)), 
    0 calc(100% - var(--edge-size))
  );
}

.double-rabbet-v3-inner-clip{
  --edge-size: 8px;
  clip-path: polygon(
    0 0, 
    100% 0, 
    100% calc(100% - var(--edge-size)), 
    calc(100% - var(--edge-size)) calc(100% - var(--edge-size)),
    calc(100% - var(--edge-size)) 100%, 
    var(--edge-size) 100%, 
    var(--edge-size) calc(100% - var(--edge-size)), 
    0 calc(100% - var(--edge-size))
  );
}


.toast-clip{
  --edge-size: 4px;
  clip-path: polygon(
    var(--edge-size) 0,
    calc(100% - var(--edge-size)) 0,
    calc(100% - var(--edge-size)) calc(var(--edge-size)/2),
    calc(100% - calc(var(--edge-size) / 2)) calc(var(--edge-size)/2),
    calc(100% - calc(var(--edge-size) / 2)) var(--edge-size),
    100% var(--edge-size),
    100% calc(100% - var(--edge-size)),
    calc(100% - calc(var(--edge-size) / 2)) calc(100% - var(--edge-size)),
    calc(100% - calc(var(--edge-size) / 2)) calc(100% - calc(var(--edge-size) / 2)),
    calc(100% - var(--edge-size)) calc(100% - calc(var(--edge-size) / 2)),
    calc(100% - var(--edge-size)) 100%,
    var(--edge-size) 100%,
    var(--edge-size) calc(100% - calc(var(--edge-size) / 2)),
    calc(var(--edge-size) / 2) calc(100% - calc(var(--edge-size) / 2)),
    calc(var(--edge-size) / 2) calc(100% - var(--edge-size)),
    0 calc(100% - var(--edge-size)),
    0 var(--edge-size),
    calc(var(--edge-size) / 2) var(--edge-size),
    calc(var(--edge-size) / 2) calc(var(--edge-size)/2),
    var(--edge-size) calc(var(--edge-size) / 2)
  );
}


.switch-clip {
  --edge-size: 2px;
  clip-path: polygon(
    0 var(--edge-size), 
    var(--edge-size) var(--edge-size), 
    var(--edge-size) 0, 
    calc(100% - var(--edge-size)) 0, 
    calc(100% - var(--edge-size)) var(--edge-size), 
    100% var(--edge-size), 
    100% calc(100% - var(--edge-size)), 
    calc(100% - var(--edge-size)) calc(100% - var(--edge-size)), 
    calc(100% - var(--edge-size)) 100%, 
    var(--edge-size) 100%, 
    var(--edge-size) calc(100% - var(--edge-size)), 
    0 calc(100% - var(--edge-size))
  );
}

.swap-clip{
  --edge-size: 26px;
  clip-path: polygon(
    var(--edge-size) 0,
    calc(100% - var(--edge-size)) 0,
    calc(100% - var(--edge-size)) calc(var(--edge-size)/2),
    calc(100% - calc(var(--edge-size) / 2)) calc(var(--edge-size)/2),
    calc(100% - calc(var(--edge-size) / 2)) var(--edge-size),
    100% var(--edge-size),
    100% calc(100% - var(--edge-size)),
    calc(100% - calc(var(--edge-size) / 2)) calc(100% - var(--edge-size)),
    calc(100% - calc(var(--edge-size) / 2)) calc(100% - calc(var(--edge-size) / 2)),
    calc(100% - var(--edge-size)) calc(100% - calc(var(--edge-size) / 2)),
    calc(100% - var(--edge-size)) 100%,
    var(--edge-size) 100%,
    var(--edge-size) calc(100% - calc(var(--edge-size) / 2)),
    calc(var(--edge-size) / 2) calc(100% - calc(var(--edge-size) / 2)),
    calc(var(--edge-size) / 2) calc(100% - var(--edge-size)),
    0 calc(100% - var(--edge-size)),
    0 var(--edge-size),
    calc(var(--edge-size) / 2) var(--edge-size),
    calc(var(--edge-size) / 2) calc(var(--edge-size)/2),
    var(--edge-size) calc(var(--edge-size) / 2)
  );
}

.output-clip{
  --edge-size: 13px;
  clip-path: polygon(
    0 0, 
    100% 0, 
    100% calc(100% - var(--edge-size)), 
    calc(100% - var(--edge-size)) calc(100% - var(--edge-size)),
    calc(100% - var(--edge-size)) 100%, 
    var(--edge-size) 100%, 
    var(--edge-size) calc(100% - var(--edge-size)), 
    0 calc(100% - var(--edge-size))
  );
}

.input-clip {
  --edge-size: 13px;
  clip-path: polygon(
    0 100%, 
    100% 100%, 
    100% var(--edge-size), 
    calc(100% - var(--edge-size)) var(--edge-size),
    calc(100% - var(--edge-size)) 0, 
    var(--edge-size) 0, 
    var(--edge-size) var(--edge-size), 
    0 var(--edge-size)
  );
}

.table-clip{
  --edge-size: 24px;
  clip-path: polygon(
    var(--edge-size) 0,
    calc(100% - var(--edge-size)) 0,
    calc(100% - var(--edge-size)) calc(var(--edge-size)/2),
    calc(100% - calc(var(--edge-size) / 2)) calc(var(--edge-size)/2),
    calc(100% - calc(var(--edge-size) / 2)) var(--edge-size),
    100% var(--edge-size),
    100% calc(100% - var(--edge-size)),
    calc(100% - calc(var(--edge-size) / 2)) calc(100% - var(--edge-size)),
    calc(100% - calc(var(--edge-size) / 2)) calc(100% - calc(var(--edge-size) / 2)),
    calc(100% - var(--edge-size)) calc(100% - calc(var(--edge-size) / 2)),
    calc(100% - var(--edge-size)) 100%,
    var(--edge-size) 100%,
    var(--edge-size) calc(100% - calc(var(--edge-size) / 2)),
    calc(var(--edge-size) / 2) calc(100% - calc(var(--edge-size) / 2)),
    calc(var(--edge-size) / 2) calc(100% - var(--edge-size)),
    0 calc(100% - var(--edge-size)),
    0 var(--edge-size),
    calc(var(--edge-size) / 2) var(--edge-size),
    calc(var(--edge-size) / 2) calc(var(--edge-size)/2),
    var(--edge-size) calc(var(--edge-size) / 2)
  );
}

.table-header-clip{
  --edge-size: 13px;
  clip-path: polygon(
    var(--edge-size) 0, 
    calc(100% - var(--edge-size)) 0,
    calc(100% - var(--edge-size)) var(--edge-size),
    100% var(--edge-size), 
    100% 100%, 
    0 100%, 
    0 var(--edge-size), 
    var(--edge-size) var(--edge-size)
  );
}


.pool-summary-inner-clip{
  --edge-size: 13px;
  clip-path: polygon(
    var(--edge-size) 0, 
    calc(100% - var(--edge-size)) 0, 
    calc(100% - var(--edge-size)) var(--edge-size), 
    100% var(--edge-size), 
    100% calc(100% - var(--edge-size)), 
    calc(100% - var(--edge-size)) calc(100% - var(--edge-size)), 
    calc(100% - var(--edge-size)) 100%, 
    var(--edge-size) 100%, 
    var(--edge-size) calc(100% - var(--edge-size)), 
    0 calc(100% - var(--edge-size)), 
    0 var(--edge-size), 
    var(--edge-size) var(--edge-size) 
  )
}


 .product-card-clip{
  --edge-size: 15px;
  clip-path: polygon(
    0% 0%, 
    calc(100% - calc(var(--edge-size) * 2)) 0%, 
    calc(100% - calc(var(--edge-size) * 2)) calc(var(--edge-size) / 2),
    calc(100% - calc(var(--edge-size) * 1.5)) calc(var(--edge-size) / 2), 
    calc(100% - calc(var(--edge-size) * 1.5)) var(--edge-size), 
    calc(100% - var(--edge-size)) var(--edge-size), 
    calc(100% - var(--edge-size)) calc(var(--edge-size) * 1.5), 
    calc(100% - calc(var(--edge-size) / 2)) calc(var(--edge-size) * 1.5), 
    calc(100% - calc(var(--edge-size) / 2)) calc(var(--edge-size) * 2),
    100% calc(var(--edge-size) * 2), 
    100% calc(100% - calc(var(--edge-size) * 2)),
    calc(100% - var(--edge-size) / 2) calc(100% - calc(var(--edge-size) * 2)),
    calc(100% - var(--edge-size) / 2) calc(100% - calc(var(--edge-size) * 1.5)),
    calc(100% - var(--edge-size)) calc(100% - calc(var(--edge-size) * 1.5)),
    calc(100% - var(--edge-size)) calc(100% - var(--edge-size)),
    calc(100% - calc(var(--edge-size) * 1.5)) calc(100% - var(--edge-size)),
    calc(100% - calc(var(--edge-size) * 1.5)) calc(100% - calc(var(--edge-size) / 2)),
    calc(100% - calc(var(--edge-size) * 2)) calc(100% - calc(var(--edge-size) / 2)),
    calc(100% - calc(var(--edge-size) * 2)) 100%,
    0% 100%
  );
}

.product-card-inner-clip {
  --edge-size: 15px;
  clip-path: polygon(
    0 0, 
    calc(100% - calc(var(--edge-size) * 1.5)) 0,
    calc(100% - calc(var(--edge-size) * 1.5)) calc(var(--edge-size) / 2),
    calc(100% - var(--edge-size)) calc(var(--edge-size) / 2),
    calc(100% - var(--edge-size)) var(--edge-size),
    calc(100% - calc(var(--edge-size) * 0.5)) var(--edge-size),
    calc(100% - calc(var(--edge-size) * 0.5)) calc(var(--edge-size) * 1.5),
    100% calc(var(--edge-size) * 1.5),
    100% calc(100% - calc(var(--edge-size) * 1.5)),
    calc(100% - calc(var(--edge-size) * 0.5)) calc(100% - calc(var(--edge-size) * 1.5)),
    calc(100% - calc(var(--edge-size) * 0.5)) calc(100% - var(--edge-size)),
    calc(100% - var(--edge-size)) calc(100% - var(--edge-size)),
    calc(100% - var(--edge-size)) calc(100% - calc(var(--edge-size) * 0.5)),
    calc(100% - calc(var(--edge-size) * 1.5)) calc(100% - calc(var(--edge-size) * 0.5)),
    calc(100% - calc(var(--edge-size) * 1.5)) 100%,
    0 100%
  );
}


.full-5-point-clip {
  --edge-size: 7px;
  clip-path: polygon(
    0 calc(var(--edge-size) * 4),
    var(--edge-size) calc(var(--edge-size) * 4), 
    var(--edge-size) calc(var(--edge-size) * 3), 
    calc(var(--edge-size) * 2) calc(var(--edge-size) * 3), 
    calc(var(--edge-size) * 2) calc(var(--edge-size) * 2), 
    calc(var(--edge-size) * 3) calc(var(--edge-size) * 2), 
    calc(var(--edge-size) * 3) var(--edge-size), 
    calc(var(--edge-size) * 4) var(--edge-size), 
    calc(var(--edge-size) * 4) 0, 
    calc(100% - calc(var(--edge-size) * 4)) 0, 
    calc(100% - calc(var(--edge-size) * 4)) var(--edge-size), 
    calc(100% - calc(var(--edge-size) * 3)) var(--edge-size), 
    calc(100% - calc(var(--edge-size) * 3)) calc(var(--edge-size) * 2), 
    calc(100% - calc(var(--edge-size) * 2)) calc(var(--edge-size) * 2), 
    calc(100% - calc(var(--edge-size) * 2)) calc(var(--edge-size) * 3), 
    calc(100% - var(--edge-size)) calc(var(--edge-size) * 3), 
    calc(100% - var(--edge-size)) calc(var(--edge-size) * 4), 
    100% calc(var(--edge-size) * 4), 
    100% calc(100% - calc(var(--edge-size) * 4)), 
    calc(100% - var(--edge-size)) calc(100% - calc(var(--edge-size) * 4)), 
    calc(100% - var(--edge-size)) calc(100% - calc(var(--edge-size) * 3)), 
    calc(100% - calc(var(--edge-size) * 2)) calc(100% - calc(var(--edge-size) * 3)),
    calc(100% - calc(var(--edge-size) * 2)) calc(100% - calc(var(--edge-size) * 2)), 
    calc(100% - calc(var(--edge-size) * 3)) calc(100% - calc(var(--edge-size) * 2)), 
    calc(100% - calc(var(--edge-size) * 3)) calc(100% - var(--edge-size)), 
    calc(100% - calc(var(--edge-size) * 4)) calc(100% - var(--edge-size)), 
    calc(100% - calc(var(--edge-size) * 4)) 100%, 
    calc(var(--edge-size) * 4) 100%, 
    calc(var(--edge-size) * 4) calc(100% - var(--edge-size)), 
    calc(var(--edge-size) * 3) calc(100% - var(--edge-size)), 
    calc(var(--edge-size) * 3) calc(100% - calc(var(--edge-size) * 2)), 
    calc(var(--edge-size) * 2) calc(100% - calc(var(--edge-size) * 2)), 
    calc(var(--edge-size) * 2) calc(100% - calc(var(--edge-size) * 3)), 
    var(--edge-size) calc(100% - calc(var(--edge-size) * 3)), 
    var(--edge-size) calc(100% - calc(var(--edge-size) * 4)), 
    0% calc(100% - calc(var(--edge-size) * 4))
  );
}

.full-5-point-inner-clip {
  --edge-size: 7px;
  clip-path: polygon(
    0 calc(var(--edge-size) * 3), 
    var(--edge-size) calc(var(--edge-size) * 3), 
    var(--edge-size) calc(var(--edge-size) * 2), 
    calc(var(--edge-size) * 2) calc(var(--edge-size) * 2), 
    calc(var(--edge-size) * 2) var(--edge-size), 
    calc(var(--edge-size) * 3) var(--edge-size), 
    calc(var(--edge-size) * 3) 0, 
    calc(100% - calc(var(--edge-size) * 3)) 0, 
    calc(100% - calc(var(--edge-size) * 3)) var(--edge-size), 
    calc(100% - calc(var(--edge-size) * 2)) var(--edge-size), 
    calc(100% - calc(var(--edge-size) * 2)) calc(var(--edge-size) * 2), 
    calc(100% - var(--edge-size)) calc(var(--edge-size) * 2), 
    calc(100% - var(--edge-size)) calc(var(--edge-size) * 3), 
    100% calc(var(--edge-size) * 3), 
    100% calc(100% - calc(var(--edge-size) * 3)), 
    calc(100% - var(--edge-size)) calc(100% - calc(var(--edge-size) * 3)), 
    calc(100% - var(--edge-size)) calc(100% - calc(var(--edge-size) * 2)), 
    calc(100% - calc(var(--edge-size) * 2)) calc(100% - calc(var(--edge-size) * 2)), 
    calc(100% - calc(var(--edge-size) * 2)) calc(100% - var(--edge-size)), 
    calc(100% - calc(var(--edge-size) * 3)) calc(100% - var(--edge-size)), 
    calc(100% - calc(var(--edge-size) * 3)) 100%, 
    calc(var(--edge-size) * 3) 100%, 
    calc(var(--edge-size) * 3) calc(100% - var(--edge-size)), 
    calc(var(--edge-size) * 2) calc(100% - var(--edge-size)), 
    calc(var(--edge-size) * 2) calc(100% - calc(var(--edge-size) * 2)), 
    var(--edge-size) calc(100% - calc(var(--edge-size) * 2)), 
    var(--edge-size) calc(100% - calc(var(--edge-size) * 3)), 
    0 calc(100% - calc(var(--edge-size) * 3))
  );
}

